import React, { createContext, useContext, useState, useCallback } from 'react';

const ProjectContext = createContext(null);

export const ProjectProvider = ({ children }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isCreatingNewProject, setIsCreatingNewProject] = useState(false);
  const [projectIssues, setProjectIssues] = useState([]);
  const [copySuccess, setCopySuccess] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const ISSUES_PER_PAGE = 5;
  const [isLoading, setIsLoading] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const fetchProjects = useCallback(async (token) => {
    const response = await fetch(`${apiBaseUrl}/api/projects`, {
      headers: { 'Authorization': token }
    });
    const data = await response.json();
    setProjects(data);
    return data;
  }, [apiBaseUrl]);

  const fetchProjectIssues = useCallback(async (projectId, page = 1) => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${apiBaseUrl}/api/projects/${projectId}/issues?page=${page}&limit=${ISSUES_PER_PAGE}`, 
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      
      const data = await response.json();
      
      if (data.issues) {
        setProjectIssues(data.issues);
        setTotalPages(data.totalPages);
        setCurrentPage(data.page);
      } else {
        console.error('Unexpected response format:', data);
        setProjectIssues([]);
        setTotalPages(1);
        setCurrentPage(1);
      }
    } catch (error) {
      console.error('Failed to fetch project issues:', error);
      setProjectIssues([]);
      setTotalPages(1);
      setCurrentPage(1);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }, [apiBaseUrl]);

const handlePageChange = useCallback(async (newPage) => {
  console.log('Context handling page change:', newPage); // Debug log
  if (newPage >= 1 && newPage <= totalPages && !isLoading) {
    try {
      setIsLoading(true);
      const token = localStorage.getItem('token');
      const response = await fetch(
        `${apiBaseUrl}/api/projects/${selectedProject._id}/issues?page=${newPage}&limit=${ISSUES_PER_PAGE}`,
        {
          headers: {
            'Authorization': token,
            'Content-Type': 'application/json'
          }
        }
      );
      
      const data = await response.json();
      if (data.issues) {
        setProjectIssues(data.issues);
        setTotalPages(data.totalPages);
        setCurrentPage(newPage);
      }
    } catch (error) {
      console.error('Error changing page:', error);
    } finally {
      setIsLoading(false);
    }
  }
}, [selectedProject, totalPages, isLoading, apiBaseUrl, ISSUES_PER_PAGE]);

  const handleSubmit = useCallback(async (projectData) => {
    const token = localStorage.getItem('token');
    const url = projectData._id
      ? `${apiBaseUrl}/api/projects/${projectData._id}`
      : `${apiBaseUrl}/api/projects`;
    
    const method = projectData._id ? 'PUT' : 'POST';
    
    const response = await fetch(url, {
      method,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(projectData),
    });
    
    const savedProject = await response.json();
    setProjects(prevProjects => projectData._id 
      ? prevProjects.map(p => p._id === savedProject._id ? savedProject : p)
      : [...prevProjects, savedProject]
    );
    
    setSelectedProject(savedProject);
    setIsCreatingNewProject(false);
    return savedProject;
  }, [apiBaseUrl]);

  const handleDelete = useCallback(async (projectId) => {
    if (!window.confirm('Are you sure you want to delete this project?')) {
      return;
    }
    
    const token = localStorage.getItem('token');
    const response = await fetch(`${apiBaseUrl}/api/projects/${projectId}`, {
      method: 'DELETE',
      headers: {
        'Authorization': token
      }
    });

    if (!response.ok) {
      throw new Error('Failed to delete project');
    }

    setProjects(prevProjects => prevProjects.filter(p => p._id !== projectId));
    setSelectedProject(null);
  }, [apiBaseUrl]);

  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopySuccess('Copied!');
      setTimeout(() => setCopySuccess(''), 2000);
    });
  }, []);

  const handleNewProject = useCallback(() => {
    setSelectedProject(null);
    setIsCreatingNewProject(true);
  }, []);

  // Effect to fetch project issues when selected project changes
  React.useEffect(() => {
    if (selectedProject) {
      setCurrentPage(1); // Reset to first page when selecting a new project
      fetchProjectIssues(selectedProject._id, 1);
    }
  }, [selectedProject, fetchProjectIssues]);

  return (
    <ProjectContext.Provider value={{
      projects,
      selectedProject,
      isCreatingNewProject,
      projectIssues,
      copySuccess,
      currentPage,
      totalPages,
      apiBaseUrl,
      isLoading,
      setProjects,
      setSelectedProject,
      setIsCreatingNewProject,
      fetchProjects,
      fetchProjectIssues,
      handleSubmit,
      handleDelete,
      copyToClipboard,
      handleNewProject,
      handlePageChange
    }}>
      {children}
    </ProjectContext.Provider>
  );
};

export const useProjects = () => {
  const context = useContext(ProjectContext);
  if (!context) {
    throw new Error('useProjects must be used within a ProjectProvider');
  }
  return context;
};