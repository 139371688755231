import React, { useState, useEffect } from 'react';
import { Button } from '../components/ui/button';
import { Input } from '../components/ui/input';
import { Label } from '../components/ui/label';
import { Textarea } from '../components/ui/textarea';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { AlertCircle, Save, Key, FileCode, Github, Bot, ListTree } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alerts';

const promptLabels = {
    summarizeError: 'Summarize Error',
    generatePRDescription: 'Generate PR Description',
    identifyAffectedFiles: 'Identify Affected Files',
    generateFixes: 'Generate Fixes'
};

const defaultFormData = {
    name: '',
    githubRepo: '',
    githubToken: '',
    llmProvider: 'openai',
    llmApiKey: '',
    llmModel: '',
    fileTypes: 'js,jsx,tsx,html',
    path_filters:'',
    prompts: {
        summarizeError: '',
        generatePRDescription: '',
        identifyAffectedFiles: '',
        generateFixes: ''
    }
};

const ProjectForm = ({ onSubmit, initialData = {}, isEdit }) => {
    const [formData, setFormData] = useState({ ...defaultFormData });
    const [processingMessage, setProcessingMessage] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Reset form when initialData or isEdit changes
    useEffect(() => {
        setFormData({
            ...defaultFormData,
            ...initialData
        });
    }, [initialData, isEdit]);

    const handleChange = (name, value) => {
        setFormData(prev => {
            if (name.startsWith('prompts.')) {
                const promptKey = name.split('.')[1];
                return {
                    ...prev,
                    prompts: {
                        ...prev.prompts,
                        [promptKey]: value
                    }
                };
            }
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);
        
        try {
            const submissionData = {
                ...formData,
                prompts: Object.fromEntries(
                    Object.entries(formData.prompts).filter(([_, v]) => v !== '')
                )
            };

            const result = await onSubmit(submissionData);
            
            if (result && result._id) {
                setProcessingMessage('Project saved successfully!');
                setTimeout(() => setProcessingMessage(''), 2000);
            }
        } catch (error) {
            setError('Failed to save project. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    const getModelPlaceholder = () => {
        switch(formData.llmProvider) {
            case 'openai':
                return 'e.g., gpt-4-turbo';
            case 'anthropic':
                return 'e.g., claude-3-opus';
            case 'openrouter':
                return 'e.g., openai/gpt-4-turbo';
            default:
                return 'Enter model name';
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-8">
        <div className="space-y-6">
            {/* Project Details Section */}
            <div className="space-y-4">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div className="space-y-2">
                        <Label htmlFor="name" className="flex items-center space-x-2">
                            <FileCode className="h-4 w-4" />
                            <span>Project Name</span>
                        </Label>
                        <Input
                            id="name"
                            value={formData.name}
                            onChange={(e) => handleChange('name', e.target.value)}
                            placeholder="Enter project name"
                            className="border-gray-200 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <Label htmlFor="githubRepo" className="flex items-center space-x-2">
                            <Github className="h-4 w-4" />
                            <span>GitHub Repo URL</span>
                        </Label>
                        <Input
                            id="githubRepo"
                            value={formData.githubRepo}
                            onChange={(e) => handleChange('githubRepo', e.target.value)}
                            placeholder="e.g., https://github.com/user/repo"
                            className="border-gray-200 focus:border-blue-500"
                            required
                        />
                    </div>
                </div>

                    <div className="space-y-2">
                        <Label htmlFor="githubToken" className="flex items-center space-x-2">
                            <Key className="h-4 w-4" />
                            <span>GitHub API Key</span>
                        </Label>
                        <Input
                            id="githubToken"
                            type="password"
                            value={formData.githubToken}
                            onChange={(e) => handleChange('githubToken', e.target.value)}
                            placeholder="Enter GitHub API key"
                            className="border-gray-200 focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">

                    <div className="space-y-2">
                        <Label htmlFor="fileTypes" className="flex items-center space-x-2">
                            <FileCode className="h-4 w-4" />
                            <span>File Types</span>
                        </Label>
                        <Input
                            id="fileTypes"
                            value={formData.fileTypes}
                            onChange={(e) => handleChange('fileTypes', e.target.value)}
                            placeholder="e.g., js,jsx,tsx,html"
                            className="border-gray-200 focus:border-blue-500"
                            required
                        />
                    </div>

                    <div className="space-y-2">
                        <Label htmlFor="path_filters" className="flex items-center space-x-2">
                            <ListTree className="h-4 w-4" />
                            <span>Path Filters</span>
                        </Label>
                        <Input
                            id="path_filters"
                            value={formData.path_filters}
                            onChange={(e) => handleChange('path_filters', e.target.value)}
                            placeholder="e.g., src/**, client/**"
                            className="border-gray-200 focus:border-blue-500"
                        />
                    </div>
            </div>
                    {/* LLM Configuration */}
                    <Card className="border-gray-200">
                        <CardHeader>
                            <CardTitle className="flex items-center space-x-2 text-lg">
                                <Bot className="h-5 w-5" />
                                <span>LLM Configuration</span>
                            </CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="space-y-2">
                                    <Label htmlFor="llmProvider">Provider</Label>
                                    <Select 
                                        value={formData.llmProvider} 
                                        onValueChange={(value) => handleChange('llmProvider', value)}
                                    >
                                        <SelectTrigger id="llmProvider">
                                            <SelectValue placeholder="Select LLM Provider" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="openai">OpenAI</SelectItem>
                                            <SelectItem value="anthropic">Anthropic</SelectItem>
                                            <SelectItem value="openrouter">OpenRouter</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                                <div className="space-y-2">
                                    <Label htmlFor="llmModel">Model</Label>
                                    <Input
                                        id="llmModel"
                                        value={formData.llmModel}
                                        onChange={(e) => handleChange('llmModel', e.target.value)}
                                        placeholder={getModelPlaceholder()}
                                        className="border-gray-200"
                                        required
                                    />
                                </div>
                            </div>
                            <div className="space-y-2">
                                <Label htmlFor="llmApiKey">API Key</Label>
                                <Input
                                    id="llmApiKey"
                                    type="password"
                                    value={formData.llmApiKey}
                                    onChange={(e) => handleChange('llmApiKey', e.target.value)}
                                    placeholder="Enter LLM API key"
                                    className="border-gray-200"
                                    required
                                />
                            </div>
                        </CardContent>
                    </Card>
                </div>

                {/* Custom Prompts Section */}
                {isEdit && (
                    <Card className="border-gray-200">
                        <CardHeader>
                            <CardTitle className="text-lg">Custom Prompts</CardTitle>
                        </CardHeader>
                        <CardContent className="space-y-4">
                            {Object.keys(formData.prompts).map(promptKey => (
                                <div key={promptKey} className="space-y-2">
                                    <Label htmlFor={`prompts.${promptKey}`}>{promptLabels[promptKey]}</Label>
                                    <Textarea
                                        id={`prompts.${promptKey}`}
                                        value={formData.prompts[promptKey]}
                                        onChange={(e) => handleChange(`prompts.${promptKey}`, e.target.value)}
                                        placeholder={`Custom prompt for ${promptLabels[promptKey]}`}
                                        className="min-h-[100px] border-gray-200"
                                    />
                                </div>
                            ))}
                        </CardContent>
                    </Card>
                )}
            </div>

            {/* Form Messages */}
            {(processingMessage || error) && (
                <Alert variant={error ? "destructive" : "success"}>
                    <AlertCircle className="h-4 w-4" />
                    <AlertDescription>
                        {error || processingMessage}
                    </AlertDescription>
                </Alert>
            )}

            {/* Submit Button */}
            <div className="flex justify-end">
                <Button 
                    type="submit" 
                    className="flex items-center space-x-2"
                    disabled={isSubmitting}
                >
                    <Save className="h-4 w-4" />
                    <span>{isSubmitting ? 'Saving...' : 'Save Project'}</span>
                </Button>
            </div>
        </form>
    );
};

export default ProjectForm;