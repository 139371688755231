import React from 'react'

export const Sidebar = ({ children, className, ...props }) => {
  return (
    <div className={`w-64 bg-gray-100 p-4 ${className}`} {...props}>
      {children}
    </div>
  )
}

export const SidebarItem = ({ children, className, ...props }) => {
  return (
    <div className={`py-2 ${className}`} {...props}>
      {children}
    </div>
  )
}