import React, { useState} from 'react';
import  ProjectSidebar  from './ProjectSidebar';
import { Button } from './ui/button';
import { Menu, X } from 'lucide-react';

export const Layout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
    return (
      <div className="flex flex-col md:flex-row min-h-screen bg-gray-50">
        {/* Mobile Menu Button - Now outside sidebar */}
        <Button
          variant="ghost"
          size="icon"
          className="fixed md:hidden top-4 left-4 z-50"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          <Menu className="h-5 w-5" />
        </Button>
  
        {/* Sidebar */}
        <div 
          className={`
            fixed md:relative
            inset-y-0 left-0
            transform ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'}
            md:translate-x-0 transition-transform duration-200 ease-in-out
            w-64 bg-white shadow-lg z-40
            md:flex md:flex-col
          `}
        >
          {/* Close button inside sidebar */}
          <Button
            variant="ghost"
            size="icon"
            className="absolute top-4 right-4 md:hidden"
            onClick={() => setIsSidebarOpen(false)}
          >
            <X className="h-4 w-4" />
          </Button>
          
          <ProjectSidebar />
        </div>
  
        {/* Overlay */}
        {isSidebarOpen && (
          <div 
            className="fixed inset-0 bg-black bg-opacity-50 z-30 md:hidden"
            onClick={() => setIsSidebarOpen(false)}
          />
        )}
  
        {/* Main Content */}
        <main className="flex-1 p-4 md:p-8 w-full md:ml-0 mt-16 md:mt-0">
          <div className="max-w-6xl mx-auto">
            {children}
          </div>
        </main>
      </div>
    );
  };
  
  export default Layout;