import React from 'react';
import { Loader2 } from 'lucide-react';

export const LoadingSpinner = ({ message = 'Loading your workspace...' }) => (
  <div className="h-screen flex items-center justify-center bg-gray-50">
    <div className="flex flex-col items-center space-y-4">
      <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      <p className="text-gray-600 font-medium">{message}</p>
    </div>
  </div>
);

// Alternatively, for inline loading:
export const InlineLoadingSpinner = () => (
  <div className="flex items-center justify-center p-4">
    <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
  </div>
);

export default LoadingSpinner;