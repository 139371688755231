import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from './components/Layout';
import { ProjectProvider } from './context/ProjectContext';
import MainContent from './components/MainContent';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorAlert from './components/ErrorAlert';
import { useProjects } from './context/ProjectContext';

// Wrapper component to handle initial data fetching
const AppContent = ({ user, setError }) => {
  const { fetchProjects } = useProjects();
  const hasFetchedData = useRef(false);

  useEffect(() => {
    const fetchInitialData = async () => {
      if (hasFetchedData.current) return;
      hasFetchedData.current = true;

      try {
        const token = localStorage.getItem('token');
        await fetchProjects(token);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
        setError('Failed to load projects. Please try again.');
      }
    };

    fetchInitialData();
  }, [fetchProjects, setError]);

  return <MainContent user={user} />;
};

function App() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const hasFetchedUser = useRef(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (hasFetchedUser.current) return;
      hasFetchedUser.current = true;

      try {
        const token = localStorage.getItem('token');
        if (!token) {
          navigate('/');
          return;
        }
        
        const response = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/api/user`,
          { headers: { 'Authorization': token } }
        );
        
        if (response.ok) {
          const userData = await response.json();
          setUser(userData);
        } else {
          localStorage.removeItem('token');
          navigate('/');
        }
      } catch (error) {
        console.error('Failed to fetch user:', error);
        setError('Failed to load user data. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchUser();
  }, [navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <ErrorAlert error={error} />;
  }

  return (
    <ProjectProvider>
      <Layout>
        <AppContent user={user} setError={setError} />
      </Layout>
    </ProjectProvider>
  );
}

export default App;