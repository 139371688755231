import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { FileCode, Edit, Trash2, Copy, Bot } from 'lucide-react';
import { useProjects } from '../context/ProjectContext';

const ProjectDetailsCard = ({ project, onCopy, copySuccess, onDelete }) => {
  const { setIsCreatingNewProject } = useProjects();

  return (
    <Card className="mb-4 md:mb-8 shadow-lg hover:shadow-xl transition-shadow">
      <CardHeader className="border-b border-gray-100">
        <CardTitle className="flex flex-col sm:flex-row sm:justify-between sm:items-center gap-3">
          <div className="flex items-center space-x-2 min-w-0">
            <FileCode className="h-5 w-5 text-blue-500 flex-shrink-0" />
            <span className="text-xl md:text-2xl font-semibold truncate">
              {project.name}
            </span>
          </div>
          <div className="flex space-x-2 self-end sm:self-auto">
            <Button 
              variant="ghost" 
              size="sm"
              onClick={() => setIsCreatingNewProject(true)}
              className="hover:bg-gray-100"
            >
              <Edit className="h-4 w-4 text-gray-600" />
              <span className="sr-only">Edit project</span>
            </Button>
            <Button 
              variant="ghost" 
              size="sm"
              onClick={() => onDelete(project._id)}
              className="hover:bg-red-50"
            >
              <Trash2 className="h-4 w-4 text-red-500" />
              <span className="sr-only">Delete project</span>
            </Button>
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent className="space-y-6 pt-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="space-y-3">
            <div className="flex items-start gap-3">
              <FileCode className="h-4 w-4 text-gray-500 mt-1 flex-shrink-0" />
              <div className="min-w-0 flex-1">
                <p className="font-medium text-sm text-gray-600">GitHub Repo</p>
                <p className="text-sm break-all">
                  {project.githubRepo || 'N/A'}
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-3">
          <div className="flex items-start space-x-2">
            <Bot className="h-4 w-4 text-gray-500 mt-1" />
            <div>
              <p className="font-medium text-sm text-gray-600">LLM Configuration</p>
              <p className="text-sm">Provider: {project.llmProvider || 'N/A'}</p>
              <p className="text-sm">Model: {project.llmModel || 'N/A'}</p>
            </div>
          </div>
        </div>
        </div>
        
        {project.webhook_url && (
  <div className="mt-6 p-4 bg-gray-50 rounded-lg border border-gray-100">
    <div className="space-y-3">
      <p className="font-medium text-sm text-gray-600">Webhook URL</p>
      <div className="flex flex-col sm:flex-row gap-3 items-start">
        <code className="flex-1 p-3 text-sm bg-white rounded border border-gray-200 font-mono text-gray-600 break-all">
          {project.webhook_url}
        </code>
        <Button
          variant="outline"
          size="sm"
          onClick={() => onCopy(project.webhook_url)}
          className="whitespace-nowrap self-start"
        >
          <Copy className="h-4 w-4 mr-2" />
          {copySuccess || 'Copy URL'}
        </Button>
      </div>
    </div>
  </div>
)}
      </CardContent>
    </Card>
  );
};

export default ProjectDetailsCard;