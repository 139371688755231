import React from 'react';
import { Card, CardContent, CardHeader, CardTitle } from '../components/ui/card';
import { FileCode, Gauge, Plus } from 'lucide-react';
import { useProjects } from '../context/ProjectContext';
import ProjectForm from './ProjectForm';
import ProjectDetailsCard from './ProjectDeteilsCard';
import RecentActivityCard from './RecentActivityCard';

const MainContent = ({ user }) => {
  const { 
    selectedProject, 
    isCreatingNewProject,
    projects,
    projectIssues,
    handleSubmit,
    handleDelete,
    copyToClipboard,
    copySuccess,
    setIsCreatingNewProject,
    currentPage,
    totalPages,
    handlePageChange,
    isLoading // Get isLoading from context instead
  } = useProjects();

  const handleEditClick = () => {
    setIsCreatingNewProject(true);
  };

  const isEditMode = isCreatingNewProject && selectedProject;
  const isCreateMode = isCreatingNewProject && !selectedProject;

  return (
    <>
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
          <Gauge className="h-8 w-8 inline-block mr-2 text-blue-500" />
          LogsFixer
        </h1>
        {user && (
          <div className="text-sm text-gray-600">
            Welcome, {user.name}
          </div>
        )}
      </div>
      
      {selectedProject && !isCreatingNewProject && (
        <>
          <ProjectDetailsCard 
            project={selectedProject}
            onCopy={copyToClipboard}
            copySuccess={copySuccess}
            onEdit={handleEditClick}
            onDelete={handleDelete}
          />
          <RecentActivityCard 
            projectIssues={projectIssues}
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
            isLoading={isLoading} // Pass isLoading from context
          />
        </>
      )}

      {(isCreateMode || isEditMode || (!selectedProject && projects.length === 0)) && (
        <Card className="shadow-lg">
          <CardHeader>
            <CardTitle className="flex items-center space-x-2">
              <Plus className="h-5 w-5 text-blue-500" />
              <span>
                {isEditMode ? 'Edit Project' : 'Create New Project'}
              </span>
            </CardTitle>
          </CardHeader>
          <CardContent>
            <ProjectForm 
              onSubmit={handleSubmit}
              initialData={isEditMode ? selectedProject : {}}
              isEdit={isEditMode}
            />
          </CardContent>
        </Card>
      )}

      {!selectedProject && !isCreatingNewProject && projects.length > 0 && (
        <div className="text-center py-12">
          <FileCode className="h-12 w-12 text-gray-300 mx-auto mb-4" />
          <h3 className="text-lg font-medium text-gray-900">Select a Project</h3>
          <p className="text-gray-500 mt-2">Choose a project from the sidebar to view its details</p>
        </div>
      )}
    </>
  );
};

export default MainContent;