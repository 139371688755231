import React, { useState } from 'react';
import { Bot, GitPullRequest, Webhook, Zap, ArrowRight, ChartArea, Terminal, Shield, Loader2, AlertCircle } from 'lucide-react';
import { GoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { Alert, AlertDescription } from '../components/ui/alerts';

function LandingPage() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleGoogleSuccess = async (credentialResponse) => {
    setIsLoading(true);
    setError('');

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/google`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ credential: credentialResponse.credential }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.setItem('token', data.token);
        navigate('/dashboard');
      } else {
        setError(data.message || 'Authentication failed. Please try again.');
      }
    } catch (error) {
      setError('Connection error. Please check your internet connection and try again.');
      console.error('Error during Google authentication:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleError = () => {
    setError('Login failed. Please try again.');
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
      {/* Hero Section */}
      <header className="container mx-auto px-6 pt-6 pb-16">
        <nav className="flex justify-between items-center mb-16">
          <div className="flex items-center space-x-2">
            <ChartArea className="h-8 w-8 text-blue-400" />
            <span className="text-xl font-bold">LogsFixer</span>
          </div>
          <div className="flex items-center space-x-8">
            {/* <button className="hover:text-blue-400 transition-colors">Features</button>
            <button className="hover:text-blue-400 transition-colors">Pricing</button>
            <button className="hover:text-blue-400 transition-colors">Docs</button> */}
            {isLoading ? (
              <div className="flex items-center space-x-2 text-blue-400">
                <Loader2 className="h-5 w-5 animate-spin" />
                <span>Signing in...</span>
              </div>
            ) : (
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                theme="outline"
                size="large"
                text="signin_with"
                shape="rectangular"
                width={200}
              />
            )}
          </div>
        </nav>

        {error && (
          <div className="max-w-md mx-auto mb-8">
            <Alert variant="destructive">
              <AlertCircle className="h-4 w-4" />
              <AlertDescription>{error}</AlertDescription>
            </Alert>
          </div>
        )}

<div className="max-w-4xl mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-blue-400 to-purple-500 text-transparent bg-clip-text">
            Rapidly Resolve Production Errors with AI-Powered Analysis
          </h1>
          <p className="text-xl text-slate-300 mb-8">
            Turn production logs into instant solutions. Our AI analyzes errors in real-time, 
            providing quick fixes to prevent recurring issues and minimize user impact.
          </p>
          <div className="flex justify-center space-x-4">
            <button className="bg-blue-500 hover:bg-blue-600 px-8 py-3 rounded-lg flex items-center space-x-2 transition-colors">
              <span>Start Free Trial</span>
              <ArrowRight className="w-4 h-4" />
            </button>
            <button className="border border-slate-600 hover:border-blue-400 px-8 py-3 rounded-lg transition-colors">
              Watch Demo
            </button>
          </div>
        </div>
      </header>

      {/* Rest of the sections remain unchanged */}
      {/* Features Grid */}
      <section className="container mx-auto px-6 py-24">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-slate-800/50 p-8 rounded-xl border border-slate-700 hover:border-blue-500 transition-colors">
            <div className="bg-blue-500/10 p-3 rounded-lg w-fit mb-4">
              <Webhook className="h-6 w-6 text-blue-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3">Real-time Monitoring</h3>
            <p className="text-slate-400">
              Connect directly to your existing monitoring setup via webhooks. Get instant notifications and analysis when errors occur.
            </p>
          </div>

          <div className="bg-slate-800/50 p-8 rounded-xl border border-slate-700 hover:border-blue-500 transition-colors">
            <div className="bg-blue-500/10 p-3 rounded-lg w-fit mb-4">
              <Zap className="h-6 w-6 text-blue-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3">AI-Powered Analysis</h3>
            <p className="text-slate-400">
              Advanced machine learning algorithms analyze error patterns and identify root causes with incredible accuracy.
            </p>
          </div>

          <div className="bg-slate-800/50 p-8 rounded-xl border border-slate-700 hover:border-blue-500 transition-colors">
            <div className="bg-blue-500/10 p-3 rounded-lg w-fit mb-4">
              <GitPullRequest className="h-6 w-6 text-blue-400" />
            </div>
            <h3 className="text-xl font-semibold mb-3">Automated Fixes</h3>
            <p className="text-slate-400">
              Receive automated pull requests with suggested fixes, complete with detailed explanations and test cases.
            </p>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <section className="container mx-auto px-6 py-24 bg-slate-800/30">
        <h2 className="text-3xl font-bold text-center mb-16">How It Works</h2>
        <div className="max-w-4xl mx-auto">
          <div className="space-y-12">
            <div className="flex items-start space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Terminal className="h-6 w-6 text-blue-400" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">1. Connect Your Application</h3>
                <p className="text-slate-400">
                  Integrate with a single line of code or connect via your existing error monitoring system.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Bot className="h-6 w-6 text-blue-400" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">2. AI Analysis Begins</h3>
                <p className="text-slate-400">
                  Our AI immediately starts analyzing errors, stack traces, and application context to understand the root cause.
                </p>
              </div>
            </div>

            <div className="flex items-start space-x-6">
              <div className="bg-blue-500/10 p-3 rounded-lg">
                <Shield className="h-6 w-6 text-blue-400" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-2">3. Receive Intelligent Solutions</h3>
                <p className="text-slate-400">
                  Get automated pull requests with fixes, complete with documentation and tests, ready for review.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="container mx-auto px-6 py-24">
        <div className="bg-gradient-to-r from-blue-600 to-purple-600 rounded-2xl p-12 text-center">
          <h2 className="text-3xl font-bold mb-4">Ready to eliminate production errors?</h2>
          <p className="text-xl text-blue-100 mb-8">
            Join thousands of developers who trust LogsFixer to keep their applications running smoothly.
          </p>
          <div className="flex justify-center space-x-4">
            <button className="bg-white text-blue-600 px-8 py-3 rounded-lg font-semibold hover:bg-blue-50 transition-colors">
              Start Free Trial
            </button>
            <button className="border border-white px-8 py-3 rounded-lg font-semibold hover:bg-white/10 transition-colors">
              Schedule Demo
            </button>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="border-t border-slate-800 py-12">
        <div className="container mx-auto px-6">
          <div className="flex justify-between items-center">
            <div className="flex items-center space-x-2">
              <ChartArea className="h-6 w-6 text-blue-400" />
              <span className="font-semibold">LogsFixer</span>
            </div>
            <div className="text-slate-400">
              © 2024 LogsFixer. All rights reserved.
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default LandingPage;