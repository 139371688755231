import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SidebarItem } from '../components/ui/sidebar';
import { Button } from '../components/ui/button';
import { 
  ChevronDown, 
  ChevronRight, 
  LogOut, 
  Plus, 
  FileCode 
} from 'lucide-react';
import { useProjects } from '../context/ProjectContext';

const ProjectSidebar = () => {
    const navigate = useNavigate();
    const [isProjectListExpanded, setIsProjectListExpanded] = useState(true);
    
    const { 
      projects, 
      selectedProject, 
      setSelectedProject, 
      setIsCreatingNewProject 
    } = useProjects();
  
    const handleLogout = () => {
      localStorage.removeItem('token');
      navigate('/');
    };
  
    const handleNewProject = () => {
      setSelectedProject(null);
      setIsCreatingNewProject(true);
    };
  

  return (
    <>
      <div className="flex-1">
        <SidebarItem className="p-4 border-b border-gray-100">
          <div className="flex justify-between items-center">
            <h2 className="text-xl font-bold text-gray-900">Projects</h2>
            {/* Space reserved for close button from Layout */}
            <div className="w-8 md:hidden"></div>
          </div>
        </SidebarItem>
        
        <SidebarItem 
          className="p-3 cursor-pointer flex items-center hover:bg-gray-50 transition-colors"
          onClick={() => setIsProjectListExpanded(prev => !prev)}
        >
          {isProjectListExpanded ? 
            <ChevronDown className="h-4 w-4 mr-2 text-gray-500" /> : 
            <ChevronRight className="h-4 w-4 mr-2 text-gray-500" />
          }
          <span className="font-medium text-gray-700">Project List</span>
        </SidebarItem>
        
        {isProjectListExpanded && (
          <div className="py-2">
            {projects.map(project => (
              <SidebarItem 
                key={project._id}
                className={`p-2 pl-6 cursor-pointer hover:bg-gray-50 transition-colors
                  ${selectedProject?._id === project._id ? 'bg-blue-50 text-blue-600' : 'text-gray-600'}`}
                onClick={() => {
                  setSelectedProject(project);
                  setIsCreatingNewProject(false);
                }}
              >
                <div className="flex items-center space-x-2">
                  <FileCode className="h-4 w-4 flex-shrink-0" />
                  <span className="truncate">{project.name}</span>
                </div>
              </SidebarItem>
            ))}
          </div>
        )}
      </div>

      <div className="p-4 border-t border-gray-100">
        <div className="space-y-2">
          <Button 
            className="w-full justify-start" 
            onClick={handleNewProject}
            variant="outline"
          >
            <Plus className="mr-2 h-4 w-4" />
            New Project
          </Button>
          <Button 
            className="w-full justify-start text-gray-600 hover:text-gray-900"
            onClick={handleLogout}
            variant="ghost"
          >
            <LogOut className="mr-2 h-4 w-4" />
            Logout
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProjectSidebar;