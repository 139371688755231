import React, { useRef } from 'react';
import { Card, CardHeader, CardTitle, CardContent } from './ui/card';
import { Badge } from './ui/badge';
import { Button } from './ui/button';
import { 
  AlertCircle,
  Clock,
  GitPullRequest,
  BarChart2,
  MonitorPlay,
  ChevronLeft,
  ChevronRight,
  Loader2
} from 'lucide-react';
import { cn } from '../lib/utils'; // Make sure you have this utility

const RecentActivityCard = ({ 
  projectIssues = [], 
  currentPage = 1, 
  totalPages = 1, 
  onPageChange, 
  isLoading = false 
}) => {
  const cardRef = useRef(null);
  const scrollPositionRef = useRef(0);
  
  const handlePageChange = (e, newPage) => {
    e.preventDefault();
    if (newPage >= 1 && newPage <= totalPages && !isLoading) {
      scrollPositionRef.current = window.scrollY;
      onPageChange(newPage);
    }
  };

  return (
    <Card ref={cardRef} className="mb-4 md:mb-8 shadow-lg bg-white relative overflow-hidden">
      <CardHeader className="border-b border-gray-100">
        <CardTitle className="flex items-center justify-between">
          <div className="flex items-center space-x-2 text-xl md:text-2xl">
            <BarChart2 className="h-6 w-6 text-blue-500" />
            <span>Monitoring Activity</span>
          </div>
          {isLoading && (
            <Loader2 className="h-5 w-5 animate-spin text-blue-500" />
          )}
        </CardTitle>
      </CardHeader>
      <CardContent className="p-0 relative min-h-[400px]">
        <div
          className={cn(
            "transition-opacity duration-300",
            isLoading ? "opacity-40 pointer-events-none" : "opacity-100"
          )}
        >
          {projectIssues.length > 0 ? (
            <>
              <ul className="divide-y divide-gray-100">
                {projectIssues.map((issue) => (
                  <li 
                    key={issue._id} 
                    className="p-4 hover:bg-gray-50 transition-colors"
                  >
                    <div className="flex flex-col space-y-3">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center space-x-3">
                          <div className="flex items-center space-x-2">
                            <AlertCircle className="h-5 w-5 text-amber-500 flex-shrink-0" />
                            <a
                              href={issue.issue_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800 hover:underline font-medium"
                            >
                              Alert
                            </a>
                          </div>
                          <Badge variant="outline" className="text-xs">
                            {issue.events?.length || 0} events
                          </Badge>
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <Clock className="h-4 w-4 text-gray-400 flex-shrink-0" />
                        <span>
                          {new Date(issue.createdAt).toLocaleDateString(undefined, {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                          })}
                        </span>
                      </div>

                      {issue.pullRequests?.length > 0 && (
                        <div className="mt-2 pt-2 border-t border-gray-100">
                          <div className="flex items-center space-x-2 mb-2">
                            <GitPullRequest className="h-4 w-4 text-purple-500" />
                            <span className="text-sm font-medium text-gray-700">
                              Fix Attempts
                            </span>
                          </div>
                          <ul className="space-y-2 pl-6">
                            {issue.pullRequests.map((pr) => (
                              <li key={pr._id} className="flex items-center space-x-2">
                                <div className="w-1 h-1 bg-gray-400 rounded-full" />
                                <a
                                  href={pr.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors line-clamp-1"
                                >
                                  {pr.title || `Pull Request #${pr.number}`}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              
              <div className="sticky bottom-0 flex items-center justify-between px-4 py-3 border-t border-gray-100 bg-white/95 backdrop-blur-sm">
                <div className="text-sm text-gray-600">
                  Page {currentPage} of {totalPages}
                </div>
                <div className="flex space-x-2">
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={(e) => handlePageChange(e, currentPage - 1)}
                    disabled={currentPage === 1 || isLoading}
                    className={cn(
                      "flex items-center space-x-1 transition-opacity duration-200",
                      isLoading && "opacity-50"
                    )}
                  >
                    <ChevronLeft className="h-4 w-4" />
                    <span>Previous</span>
                  </Button>
                  <Button
                    type="button"
                    variant="outline"
                    size="sm"
                    onClick={(e) => handlePageChange(e, currentPage + 1)}
                    disabled={currentPage === totalPages || isLoading}
                    className={cn(
                      "flex items-center space-x-1 transition-opacity duration-200",
                      isLoading && "opacity-50"
                    )}
                  >
                    <span>Next</span>
                    <ChevronRight className="h-4 w-4" />
                  </Button>
                </div>
              </div>
            </>
          ) : (
            <div className="p-8 text-center">
              <MonitorPlay className="h-12 w-12 text-gray-300 mx-auto mb-3" />
              <p className="text-gray-500 font-medium">No alerts to display</p>
              <p className="text-sm text-gray-400 mt-1">New alerts will appear here</p>
            </div>
          )}
        </div>

        {/* Loading overlay with animation */}
        {isLoading && (
          <div className="absolute inset-0 bg-white/50 backdrop-blur-[1px] flex items-center justify-center">
            <div className="flex flex-col items-center space-y-4">
              <div className="relative">
                <div className="w-12 h-12 border-4 border-blue-200 rounded-full animate-ping absolute inset-0" />
                <div className="w-12 h-12 border-4 border-blue-500 rounded-full border-t-transparent animate-spin" />
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default RecentActivityCard;