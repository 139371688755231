import React from 'react';
import { AlertCircle } from 'lucide-react';
import { Alert, AlertDescription } from '../components/ui/alerts';
import { Button } from '../components/ui/button';

export const ErrorAlert = ({ 
  error, 
  onRetry = () => window.location.reload(),
  showRetry = true 
}) => (
  <div className="h-screen flex items-center justify-center bg-gray-50">
    <div className="max-w-md w-full mx-4">
      <Alert variant="destructive" className="shadow-lg">
        <AlertCircle className="h-4 w-4" />
        <AlertDescription>{error}</AlertDescription>
      </Alert>
      
      {showRetry && (
        <Button 
          onClick={onRetry}
          className="mt-4 w-full"
        >
          Try Again
        </Button>
      )}
    </div>
  </div>
);

// Inline variant for non-critical errors
export const InlineErrorAlert = ({ error, className = '' }) => (
  <Alert 
    variant="destructive" 
    className={`mt-4 ${className}`}
  >
    <AlertCircle className="h-4 w-4" />
    <AlertDescription>{error}</AlertDescription>
  </Alert>
);

export default ErrorAlert;